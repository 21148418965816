<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="detsta">
        <div v-if="bumen_num==1&&mianbao_list.length!=1" class="dqbm">
            <p>{{mianbao_list[mianbao_list.length-1].name}}</p>
            <div @click="bianji_bumen(mianbao_list[mianbao_list.length-1])">编辑</div>
        </div>
        <div class="title">
            <!-- <p :class="bumen?'p1':'p2'" @click="renyuan">部门人员</p>
            <p :class="!bumen?'p1':'p2'" @click="jiegou">部门结构</p> -->
            <p v-for="(i,index) in renyuan_bumen_list" @click="renyuan_bumen_qiehuan(index)" :class="bumen_num==index?'p1':'p2'" :key="index">{{i}}</p>
        </div>
        <div class="box" v-if="bumen_num==0">
            <p class="p1" :style="indea==3?'opacity: 0;':''">{{qiye_name}}
                <br />
                <span @click="dangqian_zongren_shu">(当前总人数：{{qiye_dangqian_zhuce_num.staff_num}}</span>&nbsp;&nbsp;&nbsp;
                <span @click="wei_zhuce_renshu">未注册人数：{{qiye_dangqian_zhuce_num.staff_num_register_no}})</span></p>
            <div class="tit_center">
                <div v-for="(i,index) in tit_list" :key='index' @click="indea=index">
                    <img :src="index == indea?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')"  alt="">
                    <p :style="index == indea?'color:#8d52e3;':''">{{i}}</p>
                </div>
            </div>
            <div class="box_nei" v-if="indea==0">
                <div>
                    <p @click="tianjia" style="color:#fff;background:#8d52e3;border:none;">添加人员</p>
                    <p @click="tiaozheng_renyuan_bumen">调整人员部门</p>
                    <p @click="jin_piliangdaoru">导入</p>
                    <p @click="jin_daochu">导出</p>
                    <p class="sousuo_box">
                        <i class="el-icon-search"></i>
                        <input type="text" v-model="sousuo_renyuan_name" @keyup.enter='chaxun_name'>
                        <i @click="sousuo_renyuan_name='',chaxun_name()" class="el-icon-circle-close"></i>
                    </p>
                </div>
                <div>
                    <!-- <p @click="piliang_shanchu">删除</p> -->
                    <p @click="piliang_lizhi">离职</p>
                </div>
            </div>
            <div class="box_nei" v-if="indea==1" style="background:#F7DEDE;">
                <div>
                    <!-- <p @click="lizhi_renyuan" style="color:#fff;background:#8d52e3;border:none;">离职人员</p> -->
                </div>
                <div>
                    <!-- <p @click="piliang_shanchu">删除</p> -->
                    <p @click="piliang_zaizhi">再职</p>
                </div>
            </div>
            <el-table v-if="indea==0" max-height='350' ref="multipleTable" @row-click='hangdian' :cell-style="liebiao" :header-cell-style="biaotou" :data="tableData" tooltip-effect="dark" style="width: 100%;" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="42">
                </el-table-column>
                <el-table-column  label="人员" width="90">
                    <template slot-scope="scope">
                        <!-- <el-tooltip class="item" effect="dark" :content="scope.row.dept_name" placement="top">
                            <div style="width:1rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.dept_name }}</div>
                        </el-tooltip> -->
                        {{scope.row.name}}<span style="color:red;font-size:0.1rem;margin-left:0.02rem;">{{scope.row.left_flag==1?'(已退出)':''}}</span>  <!--0正常。1已退出-->
                    </template>
                    <!-- left_flag -->
                </el-table-column>
                <el-table-column  prop="dept_name" label="部门" width="110">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.dept_name" placement="top">
                            <div style="width:1rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.dept_name }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column  prop="post_name" label="职位">
                </el-table-column>
                <el-table-column label="入职时间"  width="90">
                    <template slot-scope="scope">{{ scope.row.hired_date }}</template>
                </el-table-column>
                <el-table-column  prop="mobile" label="手机号" width="130">
                </el-table-column>
                <el-table-column  prop="area" label="地区" width="130">
                </el-table-column>
                <el-table-column  label="邮箱" width="130">
                    <template slot-scope="scope"><p>{{ scope.row.email}}</p></template>
                </el-table-column>
                <el-table-column  label="注册状态">
                    <template slot-scope="scope"><p :style="scope.row.register_flag==1?'':'color:rgb(248, 110, 110)'">{{ scope.row.register_flag==1?'已注册':(scope.row.register_flag==0?'未注册':'')}}</p></template>
                </el-table-column>
            </el-table>
            <el-table v-if="indea==1" max-height='350' ref="multipleTable" :cell-style="liebiao" :header-cell-style="biaotou" :data="tableData" tooltip-effect="dark" style="width: 100%;" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="42">
                </el-table-column>
                <el-table-column prop="name"  label="人员" width="90">
                </el-table-column>
                <el-table-column  prop="dept_name" label="部门" width="130">
                </el-table-column>
                <el-table-column  prop="post_name" label="职位" width="130">
                </el-table-column>
                <el-table-column label="入职时间"   width="90">
                    <template slot-scope="scope">{{ scope.row.hired_date }}</template>
                </el-table-column>
                <el-table-column  prop="mobile" label="手机号" width="130">
                </el-table-column>
                <el-table-column  label="状态" width="130">
                    <template><p style="color:#8d52e3;">离职</p></template>
                </el-table-column>
                <el-table-column label="离职时间" width="130">
                    <template slot-scope="scope"><p style="color:#8d52e3;">{{ scope.row.sj}}</p></template>
                </el-table-column>
            </el-table>
        </div>
        <div class="box2" v-if="bumen_num==1">
            <!-- <p class="p1">赵氏集团</p> -->
            <div class="box2_mianbaoxie">
                <div v-for="(i,index) in mianbao_list" :key='index' @click="mianbao_dian(i,index)">
                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="tit_center">
                <div v-for="(i,index) in tit_listb" :key='index' @click="tupian_dianjib(index)">
                    <img :src="index == indeb?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')"  alt="">
                    <p :style="index == indeb?'color:#8d52e3;':''">{{i}}</p>
                </div>
                <div @click="tupian_dianjiaa">
                    <img :src="indeb==3?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                    <p :style="indeb==3?'color:#FFB466;':''">目标</p>
                </div>
            </div>
            <div class="box_nei" v-if="indeb==0">
                <p style="color:#fff;background:#8d52e3;border:none;" @click="dianji_tianjiabumen">添加部门</p>
                    <!-- <p>调整顺序</p> -->
                    <!-- <p @click="shangyi">上移</p>
                    <p @click="xiayi">下移</p> -->
                <div>
                    <!-- <p @click="shanchu_bumen">删除</p> -->
                    <p @click="jiegou_tingyong">停用</p>
                </div>
            </div>
            <div class="box_nei" style="background:#FFCBCB;" v-if="indeb==1">
                <div>
                    <p style="color:#fff;background:#8d52e3;border:none;" @click="tianjia_tingyong_bumen">添加停用部门</p>
                </div>
                <div>
                    <p @click="jiegou_qiyong">启用</p>
                </div>
            </div>
            <div class="box2_liebiao" v-if="indeb!=3">
                <ul>
                    <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                        <p class="box2_liebiao_tit">
                            <!-- <input ref="quana" type="checkbox" @click.stop @click="dianji_quanxian(i,$event.path[0].checked,index)"> -->
                            <img @click.stop @click="dianji_xuanzhong_bumen(i)" :src="i.zhi?require('../../assets/kuang_duigou.png'):require('../../assets/kaung_kongxin.png')" alt="">
                            {{i.dept_name}}({{i.staff_num}})
                        </p>
                        <div>
                            <p  @click.stop='shangyi(i,index)'><i class="el-icon-top"></i>上移</p>
                            <p @click.stop='xiayi(i,index)'><i class="el-icon-bottom"></i>下移</p>
                            <!-- <p @click="bianji_bumen(i)" >编辑</p>
                            <p :style="indeb!=0?'color:#CCCCCC;':''" @click="dianji_bumen(i)">进入子部门</p> -->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="box_nei" v-if="indeb==0">
                <div>
                    <!-- <p style="color:#fff;background:#8d52e3;border:none;width:120.02rem;display:none;">从部门中选择</p> -->
                    <p style="color:#fff;background:#8d52e3;border:none;" @click="tianjia">添加人员</p>
                    <p @click="tiaozheng_renyuan_bumen">调整人员部门</p>
                    <p @click="jin_piliangdaoru">导入</p>
                    <p @click="jin_daochu">导出</p>
                    <p class="sousuo_box">
                        <i class="el-icon-search"></i>
                        <input type="text" v-model="sousuo_renyuan_name" @keyup.enter='chaxun_name'>
                        <i @click="sousuo_renyuan_name='',chaxun_name()" class="el-icon-circle-close"></i>
                    </p>
                    <!-- <input type="text" v-model="sousuo_renyuan_name" @keyup.enter='chaxun_name'>
                    <i @click="sousuo_renyuan_name='',chaxun_name()" class="el-icon-circle-close"></i> -->
                </div>
                <div>
                    <!-- <p @click="piliang_shanchu">删除</p> -->
                    <p @click="piliang_lizhi">离职</p>
                </div>
            </div>
            <el-table v-if="indeb==0" ref="multipleTable"  max-height='350' @row-click='hangdian' :cell-style="liebiao" :header-cell-style="biaotou" :data="zuzhijiagou_bumenrenyuan" tooltip-effect="dark" style="width: 100%;" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="42">
                </el-table-column>
                <el-table-column label="人员" width="130">
                    <template slot-scope="scope">
                        <p style="display: flex;flex-wrap: wrap;">
                            {{ scope.row.name }} <span style="cursor: pointer;padding:0.02rem 0.08rem;border:0.01rem solid rgb(128, 64, 216);background:rgb(128, 64, 216);font-size:0.1rem;color:#fff;border-radius:0.03rem;" v-if="scope.row.zhuguan=='1'">主管</span>
                            <span style="color:red;font-size:0.1rem;margin-left:0.02rem;">{{scope.row.left_flag==1?'(已退出)':''}}</span>  <!--0正常。1已退出-->
                        </p>
                    </template>
                </el-table-column>
                <el-table-column  prop="dept_name" label="部门" width="130">
                </el-table-column>
                <el-table-column  prop="post_name" label="职位">
                </el-table-column>
                <el-table-column label="入职时间"  width="90">
                    <template slot-scope="scope">{{ scope.row.hired_date }}</template>
                </el-table-column>
                <el-table-column  prop="mobile" label="手机号" width="130">
                </el-table-column>
                <el-table-column  prop="area" label="地区" width="130">
                </el-table-column>
                <el-table-column  prop="email" label="邮箱" width="130">
                </el-table-column>
                <el-table-column  label="注册状态">
                    <template slot-scope="scope"><p :style="scope.row.register_flag==1?'':'color:rgb(248, 110, 110)'">{{ scope.row.register_flag==1?'已注册':(scope.row.register_flag==0?'未注册':'')}}</p></template>
                </el-table-column>
            </el-table>
        </div>
        <el-drawer
            :visible.sync="drawer"
            :modal='false'
            :show-close='false'
            title="成员"
            size="630">
            <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">人员信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>人员姓名
                </li>
                <li class="dd2"><input type="text" placeholder=请输入姓名 v-model="kan_list.name" ></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>部门
                </li>
                <li class="dd2"><input @click="dakai_bumen" type="text" placeholder="请输入部门" v-model="kan_list.bu_men"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>职位
                </li>
                <li class="dd2"><input type="text" placeholder="请输入职位" v-model="kan_list.zhiwei"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>入职时间
                </li>
                <li class="dd2">
                    <el-date-picker
                        v-model="kan_list.time"
                        :clearable='false'
                        type="date"
                        prefix-icon='element_tu'
                        placeholder="选择日期">
                    </el-date-picker>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>手机号
                </li>
                <li class="dd2"><input type="text" :disabled='xiugai==1' placeholder="请输入手机号" v-model="kan_list.phone"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>地区
                </li>
                <li class="dd2">
                    <area-cascader v-if="diqu_xuanze_zujian" :level="0" type="text" :placeholder="kan_list.xianshi_diqu" v-model='kan_list.diqu' :data="pcaa"></area-cascader>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span></span>邮箱
                </li>
                <li class="dd2"><input type="text" placeholder="请输入邮箱" v-model="kan_list.email"></li>
            </ul>
            <!-- <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>部门主管
                </li>
                <li class="dd2">
                    <el-select v-model="kan_list.zhuguan_shifou" :placeholder="kan_list.zhuguan_shifou">
                        <el-option
                        v-for="item in zhuguan"
                        :key="item"
                        :value="item"
                        >
                        </el-option>
                    </el-select>
                </li>
            </ul> -->
            <div class="xuan_foot">
                <!--  v-if="xiugai==0" -->
                <div style="color:#fff;background:#8d52e3" @click="xiugai==0?box_sure():box_queding()">{{xiugai==0?'添加':'确定'}}</div>
                <!-- <div v-if="xiugai==0" style="color:#fff;background:#8d52e3" @click="box_jixu">继续添加</div>   -->
                <!-- <div v-if="xiugai==1" style="color:#fff;background:#8d52e3" @click="box_queding">确定</div>  没有用了  上面三目运算解决完毕 -->
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
            </div>
        </el-drawer>
        <el-drawer
            :visible.sync="dangqian_bu_men"
            :modal='false'
            :show-close='false'
            :withHeader='true'
            title="部门"
            size="6.2rem">
            <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">部门信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>部门
                </li>
                <li class="dd2"><input type="text" placeholder=请输入该部门的名称 v-model="zuihou_bumen" ></li>
            </ul>
            <ul class="liebiao" v-if="xiugai_bumen">
                <li class="dd1">
                    <span></span>部门主管
                </li>
                <li class="dd2"><input type="text" @click="dakai_xuanzezhuguan" placeholder=请输入该部门的主管 v-model="zuihou_bumen_zhuguan" >
                    <i @click="zuihou_bumen_zhuguan='';zhuguan_xinxi={}" class="el-icon-circle-close"></i>
                </li>
            </ul>
            <!-- <ul class="liebiao" @click="dakai_xuanzezhuguan">
                <li class="dd1">
                    <span></span>部门主管
                </li>
                <li class="dd2"><input type="text" placeholder=请输入该部门的主管 v-model="zuihou_bumen_zhuguan" ></li>
            </ul> -->
            <!-- <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>是否有子部门
                </li>
                <li class="dd2">
                    <el-select v-model="xin_shifou">
                        <el-option
                        v-for="item in zicanpin"
                        :key="item"
                        :value="item"
                        >
                        </el-option>
                    </el-select>
                </li>
            </ul> -->
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>是否有目标
                </li>
                <li class="dd2" style="display:flex;align-items: center;"><p style="display:flex;align-items: center;" v-for="(i,index) in mubiao_shifou" @click="mubiao_shifou_num(index)" :key="index"><img style="margin-left:0.4rem;margin-right:0.2rem;" :src="index==mubiao_num?require('../../assets/shixin.png'):require('../../assets/kongxin.png')" alt="">{{i}}</p></li>
            </ul>
            <div class="xuan_foot">
                <div v-if="!tianjiaa" style="color:#fff;background:#8d52e3" @click="box_sure_tianjia_bumenxinxi">确定</div>
                <div v-if="tianjiaa" style="color:#fff;background:#8d52e3" @click="box_sure_bumenxinxi">确定</div>
                <!-- <div v-if='tianjiaa' style="color:#888888;border:0.01rem solid #888888" @click="box_shanchu_bumenxinxi">删除</div> -->
                <div style="color:#888888;border:0.01rem solid #888888" @click="dangqian_bu_men = false">取消</div>
            </div>
        </el-drawer>
        <el-dialog title="调整部门" :center="true" :show-close='false' :visible.sync="qiye_bumen">
            <div class="tiao_bumen">
                <!-- <div class="mianbao_box">
                    <div v-for="(i,index) in mianbao_list" :key='index' @click="mianbao_dian(i,index)">
                        <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <ul>
                    <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                        <div>
                            <img @click.stop @click="tupian_dianji(i,index)" :src="index == inde?require('../../assets/ok_blue.png'):require('../../assets/no_#ccc.png')"  alt="">
                            <p>{{i.dept_name}}</p>
                        </div>
                        <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                    </li>
                </ul> -->
                <el-tree :data="bumen_tree" ref="zuzhi_jiagou_tree" default-expand-all show-checkbox check-strictly :props="defaultProps"></el-tree>
            </div>
            <div class="foote">
                <p class="queding" @click="tiaozheng_queding">确定</p>
                <p class="quxiao" @click="tiaozheng_quxiao">取消</p>
            </div>
        </el-dialog>
        <!-- <el-dialog title="选择人员" :center="true" :show-close='false' :visible.sync="qiye_bumenb">
            <div class="tiao_bumenb">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                    <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in zuzhijiagou_bumenrenyuan" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                    <img src='' alt="">

                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="yixuan">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="dianji_queding">确定</p>
                <p class="quxiao" @click="qiye_bumenb=false">取消</p>
            </div>
        </el-dialog>  -->
        <el-dialog title="部门人员" :center="true" :show-close='false' :visible.sync="qiye_bumenb">
            <div class="tiao_bumen">
                <!-- <div class="mianbao_box">
                    <div v-for="(i,index) in mianbao_list" :key='index' @click="mianbao_dian(i,index)">
                        <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div> -->
                <ul>
                    <!-- <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                        <div> -->
                            <!-- <img @click.stop @click="tupian_dianji(index)" :src="index == inde?require('../../assets/ok_blue.png'):require('../../assets/no_#ccc.png')"  alt=""> -->
                            <!-- <p>{{i.dept_name}}</p>
                        </div>
                        <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                    </li> -->
                    <li v-for="(i,index) in zuzhijiagou_bumenrenyuan" :key="index">
                        <div>
                            <img @click.stop @click="tupian_dianji(i,index)" :src="index == inde?require('../../assets/ok_blue.png'):require('../../assets/no_#ccc.png')"  alt="">
                            <p>{{i.name}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="foote">
                <p class="queding" @click="xuanze_zhuguan_sure">确定</p>
                <p class="quxiao" @click="qiye_bumenb=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="部门" :center="true" :show-close='false' :visible.sync="ting_bumen">
            <div class="tiao_bumen">
                <div class="mianbao_box">
                    <div v-for="(i,index) in mianbao_list" :key='index' @click="mianbao_dian(i,index)">
                        <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <ul>
                    <li v-for="(i,index) in qiye_bumen_list_ting" :key="index" @click="dianji_bumen_ting(i)">
                        <div>
                            <!-- <input  @click.stop ref="have" type="checkbox" @click="dianji_xuanren(i,$event.path[0].checked,index)"> -->
                            <img @click.stop @click="dianji_xuanren(i)" :src="i.zhi?require('../../assets/kuang_duigou.png'):require('../../assets/kaung_kongxin.png')" alt="">
                            <p>{{i.dept_name}}</p>
                        </div>
                        <!-- <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt=""> -->
                    </li>
                </ul>
            </div>
            <div class="foote">
                <p class="queding" @click="xuanze_weitingyong_sure">确定</p>
                <p class="quxiao" @click="ting_bumen=false">取消</p>
            </div>
        </el-dialog>
        <div class="tree" v-if="indeb==3&&bumen_num==1">
            <p class="p1">{{qiye_name}}目标设置页</p>
            <el-tree :data="tree_bumen" :props="defaultProps" :default-expand-all='true'>
                <div class="custom-tree-node"  slot-scope="{ node, data }">
                    <p :style="data.aim_prop==1?'color:#FFB466':'color:#1A2533'"><img :src="data.aim_prop==1?require('../../assets/huang_wenjian.png'):require('../../assets/hui_wenjian.png')" alt="">{{ node.label }}</p>
                    <p><span @click.stop='quxiao_tianjia_mubiao(data)' :style="data.aim_prop==1?'color:#A5C6FD':'color:#FFB466'">{{data.aim_prop==1?'取消目标':'添加目标'}}</span>
                    <span @click.stop='tingyong_qiyong_mubiao(data)' :style="data.active==1?'color:#A5C6FD':'color:#8d52e3'">{{data.active==1?'停用':'启用'}}</span></p>
                </div>
            </el-tree>
        </div>
        <el-dialog title="调整部门" :center="true" :show-close='false' :visible.sync="zhanshi_tiaozheng_renyuan_bumen">
            <div class="tiao_bumen">
                <!-- <div class="mianbao_box">
                    <div v-for="(i,index) in mianbao_list" :key='index' @click="mianbao_dian(i,index)">
                        <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <ul>
                    <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                        <div>
                            <img @click.stop @click="tupian_dianji(i,index)" :src="index == inde?require('../../assets/ok_blue.png'):require('../../assets/no_#ccc.png')"  alt="">
                            <p>{{i.dept_name}}</p>
                        </div>
                        <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                    </li>
                </ul> -->
                <el-tree :data="renyuan_tiaozheng_bumen" node-key='id' ref="tiaozheng_renyuan_tree" @check-change="handleClick"  default-expand-all show-checkbox check-strictly :props="defaultProps"></el-tree>
            </div>
            <div class="foote">
                <p class="queding" @click="renyuan_tiaozheng_queding">确定</p>
                <p class="quxiao" @click="zhanshi_tiaozheng_renyuan_bumen=false">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import 'vue-area-linkage/dist/index.css'
import { pca, pcaa } from 'area-data'
import { update_dept_child_flag, query_ent_dept_list_of_all, query_ent_dept_list2, query_staff_list_register_not, update_dept_sort, query_ent_dept_list_aim, query_ent_staffNum_registerNum, batch_set_dept_for_many_staff, query_ent_dept_list_aim_not, update_dept_active_batch, update_staff_active_batch, query_ent_staff_list, insert_staff, query_ent_dept_list, update_staff, delete_staff, query_dept_staff_list, update_dept_info, delete_dept_info, set_ent_dept_info } from '../../api/api.js'
export default {
  name: 'detsta',
  data () {
    return {

      tableData: [],
      multipleSelection: [],
      drawer: false,
      kan_list: {
        name: '',
        bu_men: '',
        time: '',
        zhiwei: '',
        phone: '',
        diqu: '',
        xianshi_diqu: '',
        email: '',
        // zhuguan_shifou:'',
        id: ''
      },
      tit_list: ['在职', '离职'],
      tit_listb: ['启用', '停用'],
      indeb: '0',
      // zicanpin:['是','否'],
      pca: pca,
      pcaa: pcaa,
      qiye_bumen: false,
      qiye_bumen_list: [],
      mianbao_list: [
        {
          name: '',
          parent_id: '0',
          zhuguan: '',
          aim_prop: '1'
        }
      ],
      ent_id: '',
      inde: 0,
      tiaozheng_bumen_id: '',

      zuzhijiagou_bumenrenyuan: [],
      renyuan_staff_id: '',
      dangqian_bumen: false,
      dangqian_bu_men: false,
      dangqian_bumen_list: [],
      zuihou_bumen: '',
      zuihou_bumen_id: '',
      tianjiaa: true,
      zuihou_bumen_zhuguan: '',
      jichuxinxi: '',
      act: '1',
      acta: '1',
      // xin_shifou:'',
      chanpin_zhonglei_list: [],
      qiye_bumenb: false,
      text: '',
      zhuguan_xinxi: {},
      mubiao_shifou: ['有目标', '无目标'],
      mubiao_num: '0',
      ting_bumen: false,
      qiye_bumen_list_ting: [],
      weitingyong_bumen: [],
      tree_bumen: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        childflag: 'child_flag'
      },
      mubiao_shifou_id: '',

      // 20200408更改组织架构
      renyuan_bumen_list: ['部门人员', '部门结构'],
      bumen_num: 0,
      indea: '0', // 部门人员  部门结构   切换的
      xiugai: 0, // 0添加人员   1修改人员
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      bumen_tree: [],
      xiugai_bumen: false,
      renyuan_tiaozheng_bumen: [],
      zhanshi_tiaozheng_renyuan_bumen: false,
      dangqian_hang_xinxi: '',
      qiye_dangqian_zhuce_num: {},
      sousuo_renyuan_name: '',
      diqu_xuanze_zujian: false
    }
  },
  created () {
    this.qiye_name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.jichu()
    // this.bumen_liebiao()
  },
  watch: {
    indea () {
      this.jichu()
      this.sousuo_renyuan_name = ''
    },
    bumen_num () {
      this.sousuo_renyuan_name = ''
    },
    indeb () {
      this.sousuo_renyuan_name = ''
    },
    mianbao_list () {
      if (this.mianbao_list.length - 1 == 0 && this.bumen_num == 0) {
        this.dangqian_bumen = false
      } else if (this.mianbao_list.length - 1 != 0 && this.bumen_num == 1) {
        this.dangqian_bumen = true
      }
    },
    drawer () {
      if (!this.drawer) {
        this.kan_list.diqu = ''
        this.kan_list.xianshi_diqu = ''
        //   this.pcaa=''
        //   this.pca=''
        //   this.kan_list.diqu=''
      } else {
        this.diqu_xuanze_zujian = false
        setTimeout(() => {
          this.diqu_xuanze_zujian = true
        }, 0)
      }
    },
    dangqian_bu_men () {
      if (!this.dangqian_bu_men) {
        this.zuihou_bumen = ''
        this.zuihou_bumen_zhuguan = ''
        //   this.xin_shifou='',
        this.mubiao_num = '0'
        this.xiugai_bumen = false
      }
    }
  },
  methods: {
    //   基础
    jichu () {
      query_ent_staff_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          active: this.indea == 1 ? '0' : '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10149) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          for (let i = 0; i < date.length; i++) {
            if (date[i].resign_date != null) {
              date[i].sj = date[i].resign_date.slice(0, 4) + '-' + date[i].resign_date.slice(4, 6) + '-' + date[i].resign_date.slice(6)
            }
          }
          this.tableData = date

        } else if (res.data.code == 100150) {
        }
      })
      this.qiye_zhuce_num()
    },

    // 勾选产品]
    // dianji_quanxian(i,e){
    //     console.log(i)
    //     if(e){
    //         this.chanpin_zhonglei_list.push(i)
    //     }else{
    //         for(let a=0;a<this.chanpin_zhonglei_list.length;a++){
    //             if(i.cat_name==this.chanpin_zhonglei_list[a].cat_name){
    //                 this.chanpin_zhonglei_list.splice(a,1)
    //             }
    //         }
    //     }
    //     console.log(this.chanpin_zhonglei_list)
    // },
    // dakai_bumen(){
    //     this.mianbao_list.splice(1,this.mianbao_list.length)
    //     this.bumen_liebiao()
    //     this.qiye_bumen=true
    // },
    // renyuan(){
    //     if(this.bumen_num==1){
    //         this.bumen_num=0
    //         this.dangqian_bumen=false
    //     }
    // },
    // jiegou(){
    //     if(this.bumen_num==0){
    //         this.bumen_num=1
    //         this.bumen_liebiao()
    //         this.mianbao_list.splice(1,this.mianbao_list.length)
    //         query_dept_staff_list({data:{
    //             ent_id:this.$ent_id(),
    //             user_id:this.$jichuxinxi().user_id,
    //             active:this.acta
    //         }}).then(ras=>{
    //             if(ras.data.code==10149){
    //                 let listdata=JSON.parse(ras.data.body.data)
    //                 console.log(99999,listdata)
    //                 this.zuzhijiagou_bumenrenyuan=listdata
    //             }else if(ras.data.code==10150){
    //             }
    //         })
    //     }
    // },
    // 停用部门的接口
    ting_qiyong_bumen (ids) {
      // let list=[]
      // for(let i=0;i<this.chanpin_zhonglei_list.length;i++){
      //     list.push(this.chanpin_zhonglei_list[i].dept_id)
      // }
      // list=list.join(',')
      // console.log(list)
      update_dept_active_batch({
        data: {
          ent_id: this.$ent_id(),
          active: this.indeb + '',
          dept_ids: ids,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10209) {
          // indeb
          if (this.indeb == 0) {
            this.$message({
              message: '停用成功',
              type: 'success'
            })
          } else if (this.indeb == 1) {
            this.$message({
              message: '启用成功',
              type: 'success'
            })
          }

          // this.jichu()
          // list=[]
          // console.log(list)
          // for(let i=0;i<this.chanpin_zhonglei_list.length;i++){
          //     this.$refs.quana[i].checked=false
          //     console.log(this.$refs.quana[i])
          // }
          // this.chanpin_zhonglei_list=[]
          this.bumen_liebiao()
        } else if (res.data.code == 10210) {} else if (res.data.code == 10247) {
          this.$message({
            message: '该部门下存在人员，无法停用该部门',
            type: 'warning'
          })
        } else if (res.data.code == 11400) {
          this.$message({
            message: '该部门存在目标，不能停用',
            type: 'warning'
          })
        } else if (res.data.code == 11500) {
          this.$message({
            message: '有产品规则存在，不能停用',
            type: 'warning'
          })
        }
      })
    },
    // 结构停用
    jiegou_tingyong () {
      const list = []
      this.qiye_bumen_list.map(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      if (list.length != 0) {
        this.ting_qiyong_bumen(list.map(item => item.dept_id).join(','))
      } else {
        this.$message({
          message: '请选择要停用的部门',
          type: 'warning'
        })
      }
      // if(this.qiye_bumen_list.length!=0){
      //     this.ting_qiyong_bumen()
      // }else{
      //     this.$message({
      //         message: '请选择要停用的部门',
      //         type: 'warning'
      //     });
      // }
    },
    // 部门删除
    shanchu_bumen () {
      const list = []
      this.qiye_bumen_list.map(item => {
        if (item.zhi) {
          if (item.dept_id != undefined && item.dept_id.length != 0) {
            list.push(item)
          }
        }
      })
      console.log(list)
      if (list.length != 0) {
        // this.ting_qiyong_bumen(list.map(item=>item.dept_id).join(','))
        delete_dept_info({
          data: {
            id: list.map(item => item.id).join(','),
            ent_id: this.$ent_id(),
            dept_ids: list.length != 0 ? list.map(item => item.dept_id).join(',') : '',
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10163) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.bumen_liebiao()
          } else if (res.data.code == 10164) {} else if (res.data.code == 10700) {
            this.$message({
              message: '该部门下还有人员',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请选择要删除的部门',
          type: 'warning'
        })
      }
    },
    // 结构启用
    jiegou_qiyong () {
      const list = []
      this.qiye_bumen_list.map(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      if (list.length != 0) {
        this.ting_qiyong_bumen(list.map(item => item.dept_id).join(','))
      } else {
        this.$message({
          message: '请选择要启用的部门',
          type: 'warning'
        })
      }
      // if(this.chanpin_zhonglei_list.length!=0){
      //     this.ting_qiyong_bumen()
      // }else{
      //     this.$message({
      //         message: '请选择要启用的部门',
      //         type: 'warning'
      //     });
      // }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(9999999, val)
    },
    hangdian (val) {
      console.log(11111, val)
      this.dangqian_hang_xinxi = val
      this.drawer = true
      this.kan_list.name = val.name != undefined ? val.name : ''
      this.kan_list.bu_men = val.dept_name
      this.kan_list.diqu = ''
      if (val.area == undefined) {
        this.kan_list.xianshi_diqu = '请选择地区'
      } else {
        this.kan_list.xianshi_diqu = val.area
      }
      this.kan_list.email = val.email
      this.kan_list.phone = val.mobile
      this.kan_list.time = val.hired_date
      this.kan_list.zhiwei = val.post_name
      this.xiugai = 1
      this.tiaozheng_bumen_id = val.dept_id,
      this.kan_list.id = '' + val.id
      this.renyuan_staff_id = val.staff_id
      const shifou = ''
      // if(val.flag_in_charge=='0'){
      //     shifou='否'
      // }else{
      //     shifou='是'
      // }
      // this.kan_list.zhuguan_shifou=shifou
    },
    // 点击添加人员
    tianjia () {
      this.drawer = true
      this.chufaa()
    },
    // 点击触发的事件
    chufaa () {
      this.kan_list.name = ''
      this.kan_list.bu_men = ''
      this.kan_list.diqu = '请选择地区'
      this.kan_list.xianshi_diqu = '请选择地区'
      this.kan_list.email = ''
      this.kan_list.phone = ''
      this.kan_list.time = ''
      this.kan_list.zhiwei = ''
      // this.kan_list.zhuguan_shifou=''
      this.xiugai = 0
      console.log('查看', this.mianbao_list[this.mianbao_list.length - 1])
      if (this.bumen_num == 1) {
        if (this.mianbao_list.length - 1 != 0) {
          this.kan_list.bu_men = this.mianbao_list[this.mianbao_list.length - 1].name
          this.tiaozheng_bumen_id = this.mianbao_list[this.mianbao_list.length - 1].parent_id
        }
      }
    },
    p (s) {
      return s < 10 ? '0' + s : s
    },
    //   添加
    box_sure () {
      const date = this.kan_list
      console.log(date)
      if (((date.xianshi_diqu != undefined && date.xianshi_diqu.length != 0 && date.xianshi_diqu != '请选择地区') || date.diqu.length != 0) && date.time != undefined && date.zhiwei != undefined && date.name.length > 0 && date.bu_men.length != 0 && date.zhiwei.length != 0 && date.time.length != 0 && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(date.phone) && date.diqu.length) {
        const date = this.kan_list
        // let shifou=''
        const d = date.time
        const sj = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
        // sj=sj.replace(/-/g, "")
        let diqu = ''
        if (date.diqu != '') {
          diqu = date.diqu.join('/')
        } else {
          diqu = date.xianshi_diqu
        }
        // if(this.kan_list.zhuguan_shifou=='是'){
        //     shifou='1'
        // }else{
        //     shifou='0'
        // }
        if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(date.email)) {
          date.email = null
        }
        insert_staff({
          data: {
            name: date.name,
            mobile: date.phone,
            dept_name: date.bu_men,
            dept_id: this.tiaozheng_bumen_id,
            post_name: date.zhiwei,
            hired_date: sj,
            email: date.email,
            area: diqu,
            // flag_in_charge:shifou,
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10160) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          } else if (res.data.code == 10159) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            if (this.bumen_num == 0) {
              this.jichu()
            } else {
              this.bumen_liebiao()
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                  user_id: this.$jichuxinxi().user_id,
                  active: this.acta
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  // if(this.mianbao_list[this.mianbao_list.length-1].leader_id.length!=0){
                  //     for(let a=0;a<listdata.length;a++){
                  //         if(listdata[a].staff_id==this.mianbao_list[this.mianbao_list.length-1].leader_id){
                  //             this.$set(listdata[a],'zhuguan','1')
                  //         }
                  //     }
                  // }
                  // this.zuzhijiagou_bumenrenyuan=listdata
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }
                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                } else if (ras.data.code == 10150) {
                }
              })
            }
            this.drawer = false
          } else if (res.data.code == 10167) {
            this.$message({
              message: '该部门已存在主管',
              type: 'warning'
            })
          } else if (res.data.code == 600) {
            this.$message({
              message: '员工手机号重复',
              type: 'warning'
            })
          } else if (res.data.code == 11300) {
            this.$message({
              message: '该员工已离职，请去离职列表给该员工再职',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 保存并继续
    box_jixu () {
      const date = this.kan_list
      console.log(date.time)
      if (date.name.length > 0 && date.bu_men != '' && date.zhiwei != '' && date.time != '' && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(date.phone) && date.diqu != '') {
        const date = this.kan_list
        const shifou = ''
        const d = date.time
        const sj = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
        // sj=sj.replace(/-/g, "")
        if (date.diqu != '') {
          console.log(date.diqu)
          diqu = date.diqu.join('/')
        } else {
          diqu = date.xianshi_diqu
        }
        // if(this.kan_list.zhuguan_shifou=='是'){
        //     shifou='1'
        // }else{
        //     shifou='0'
        // }
        if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(date.email)) {
          date.email = null
        }
        insert_staff({
          data: {
            name: date.name,
            mobile: date.phone,
            dept_name: date.bu_men,
            dept_id: this.tiaozheng_bumen_id,
            post_name: date.zhiwei,
            hired_date: sj,
            email: date.email,
            area: diqu,
            // flag_in_charge:shifou,
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10160) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          } else if (res.data.code == 10159) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.jichu()
            this.chufaa()
          } else if (res.data.code == 10167) {
            this.$message({
              message: '该部门已存在主管',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      if (this.mianbao_list.length - 1 != index) {
        console.log(i)
        if (this.indeb == 1) {
          query_ent_dept_list2({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              active: this.indeb == 1 ? '0' : '1'
            // this.acta
            }
          }).then(res => {
            // console.log(11111,res)
            if (res.data.code == 10168) {
              var date = JSON.parse(res.data.body.data)
              console.log('部门', date)
              date.map(item => { this.$set(item, 'zhi', false) })
              this.qiye_bumen_list = date
              this.qiye_bumen_list_ting = date
              this.mianbao_list.splice(index + 1, this.mianbao_list.length)
              if (this.mianbao_list.length == 1) {
                this.dangqian_bumen = false
              }
              if (this.bumen_num == 1) {
                query_dept_staff_list({
                  data: {
                    ent_id: this.$ent_id(),
                    dept_id: i.parent_id,
                    user_id: this.$jichuxinxi().user_id,
                    active: this.indeb == 1 ? '0' : '1'
                  }
                }).then(ras => {
                  if (ras.data.code == 10149) {
                    const listdata = JSON.parse(ras.data.body.data)
                    console.log(99999, listdata)
                    if (this.bumen_num == 0) {
                      this.tableData = listdata
                    } else if (this.bumen_num == 1) {
                      const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                      if (bumen_zhuguan_xinxi != null) {
                        if (this.mianbao_list[this.mianbao_list.length - 1].leader_id != undefined && this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                          for (let a = 0; a < listdata.length; a++) {
                            if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                              this.$set(listdata[a], 'zhuguan', '1')
                            }
                          }
                        }
                      } else {
                        this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                        this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                      }
                      console.log(99999, listdata)
                      this.zuzhijiagou_bumenrenyuan = listdata
                    }
                  } else if (ras.data.code == 10150) {
                  }
                })
              }
            } else if (res.data.code == 10169) {
            }
          })
        } else if (this.indeb == 0) {
          query_ent_dept_list_of_all({
            data: {
              ent_id: this.$ent_id(),
              parent_id: i.parent_id,
              user_id: this.$jichuxinxi().user_id,
              active: this.indeb == 1 ? '0' : '1'
            // this.acta
            }
          }).then(res => {
            // console.log(11111,res)
            if (res.data.code == 10168) {
              var date = JSON.parse(res.data.body.data)
              console.log('部门', date)
              date.map(item => { this.$set(item, 'zhi', false) })
              this.qiye_bumen_list = date
              this.qiye_bumen_list_ting = date
              this.mianbao_list.splice(index + 1, this.mianbao_list.length)
              if (this.mianbao_list.length == 1) {
                this.dangqian_bumen = false
              }
              if (this.bumen_num == 1) {
                query_dept_staff_list({
                  data: {
                    ent_id: this.$ent_id(),
                    dept_id: i.parent_id,
                    user_id: this.$jichuxinxi().user_id,
                    active: this.indeb == 1 ? '0' : '1'
                  }
                }).then(ras => {
                  if (ras.data.code == 10149) {
                    const listdata = JSON.parse(ras.data.body.data)
                    console.log(99999, listdata)
                    if (this.bumen_num == 0) {
                      this.tableData = listdata
                    } else if (this.bumen_num == 1) {
                      const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                      if (bumen_zhuguan_xinxi != null) {
                        if (this.mianbao_list[this.mianbao_list.length - 1].leader_id != undefined && this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                          for (let a = 0; a < listdata.length; a++) {
                            if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                              this.$set(listdata[a], 'zhuguan', '1')
                            }
                          }
                        }
                      } else {
                        this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                        this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                      }
                      console.log(99999, listdata)
                      this.zuzhijiagou_bumenrenyuan = listdata
                    }
                  } else if (ras.data.code == 10150) {
                  }
                })
              }
            } else if (res.data.code == 10169) {
            }
          })
        }
      }
    },
    // 点击部门获取下面子部门
    dianji_bumen (i) {
      console.log(44444, i)

      if (this.indeb == 0) {
        // if(i.child_flag==1){
        query_ent_dept_list_of_all({
          data: {
            ent_id: this.$ent_id(),
            parent_id: i.dept_id,
            user_id: this.$jichuxinxi().user_id,
            active: this.indeb == 1 ? '0' : '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.map(item => { this.$set(item, 'zhi', false) })
            this.qiye_bumen_list = date
            this.mianbao_list.push({ name: i.dept_name, parent_id: i.dept_id, leader_name: i.leader_name != undefined ? i.leader_name : '', leader_id: i.dept_leader_id != undefined ? i.dept_leader_id : '', aim_prop: i.aim_prop != undefined ? i.aim_prop : '0' })
            this.inde = -1
            if (this.bumen_num == 1) {
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  dept_id: i.dept_id,
                  user_id: this.$jichuxinxi().user_id,
                  active: '1'
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                  console.log(this.zuzhijiagou_bumenrenyuan)
                } else if (ras.data.code == 10150) {
                }
              })
            }
          } else if (res.data.code == 10169) {
          }
        })
        // }else{
        //     this.$message({
        //         message: '该部门没有子部门',
        //         type: 'warning'
        //     });
        // }
      }
    },
    // 停用筛选点击子部门
    dianji_bumen_ting (i) {
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          parent_id: i.dept_id,
          user_id: this.$jichuxinxi().user_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.map(item => { this.$set(item, 'zhi', false) })
          this.qiye_bumen_list_ting = date
          this.mianbao_list.push({ name: i.dept_name, parent_id: i.dept_id })
          this.inde = -1
          // if(this.bumen_num==1){
          //     query_dept_staff_list({data:{
          //         ent_id:this.$ent_id(),
          //         dept_id:i.dept_id,
          //         user_id:this.$jichuxinxi().user_id,
          //         active:'1'
          //     }}).then(ras=>{
          //         if(ras.data.code==10149){
          //             let listdata=JSON.parse(ras.data.body.data)
          //             console.log(99999,listdata)
          //             this.zuzhijiagou_bumenrenyuan=listdata
          //         }else if(ras.data.code==10150){
          //         }
          //     })
          // }
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门',
      //         type: 'warning'
      //     });
      // }
    },
    tupian_dianji (i, index) {
      this.inde = index
      this.zhuguan_xinxi = i
    },
    // 图片点击
    // tupian_dianjia(index){
    //     this.indea=index+'';
    //     if(index==0){
    //         this.act='1'
    //         this.jichu()
    //     }else if(index==1){
    //         this.act='0'
    //         this.jichu()
    //     }
    // },
    // 图片点击
    tupian_dianjib (index) {
      this.indeb = index + ''
      if (index == 0) {
        this.acta = '1'
        this.bumen_liebiao()
      } else if (index == 1) {
        this.acta = '0'
        this.bumen_liebiao()
      }
    },
    // 调整确定及取消
    // tiaozheng_queding(){
    //     console.log(this.inde)
    //     if(this.inde!=-1){
    //         console.log(4444,this.qiye_bumen_list[this.inde])
    //         this.tiaozheng_bumen_id=this.qiye_bumen_list[this.inde].dept_id
    //         this.kan_list.bu_men=this.qiye_bumen_list[this.inde].dept_name
    //         this.qiye_bumen=false
    //         this.inde=-1
    //     }else{
    //         this.$message({
    //             message: '请选择您要调整的部门',
    //             type: 'warning'
    //         });
    //     }
    // },
    // tiaozheng_quxiao(){
    //     this.qiye_bumen=false
    // },
    // 修改成员确定。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    box_queding () {
      let date = ''
      date = this.kan_list
      console.log(11111, date)
      if (((date.xianshi_diqu != undefined && date.xianshi_diqu.length != 0 && date.xianshi_diqu != '请选择地区') || date.diqu.length != 0) && date.time != undefined && date.zhiwei != undefined && date.name.length != 0 && date.bu_men.length != 0 && date.zhiwei.length != 0 && date.time.length != 0 && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(date.phone)) {
        const shifou = ''
        let sj = ''
        const d = date.time
        let diqu = ''
        console.log(date.time.length)
        if (date.time.length <= 10) {
          sj = date.time
          console.log(sj.length)
        } else {
          sj = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
        }
        if (date.diqu != '') {
          diqu = date.diqu.join('/')
        } else {
          diqu = date.xianshi_diqu
        }
        if (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(date.email)) {
        } else {
          date.email = null
        }
        //  ;
        // if(this.kan_list.zhuguan_shifou=='是'){
        //     shifou='1'
        // }else{
        //     shifou='0'
        // }
        update_staff({
          data: {
            name: date.name,
            // mobile:date.phone,
            dept_name: date.bu_men,
            dept_id: this.tiaozheng_bumen_id,
            post_name: date.zhiwei,
            hired_date: sj,
            email: date.email,
            yuan_dept_id: this.dangqian_hang_xinxi.dept_id,
            area: diqu,
            // flag_in_charge:shifou,
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            // id:date.id,
            staff_id: this.renyuan_staff_id,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10148) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          } else if (res.data.code == 10147) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.drawer = false
            this.jichu()
            if (this.bumen_num == 1) {
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  user_id: this.$jichuxinxi().user_id,
                  dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                  active: '1'
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                } else if (ras.data.code == 10150) {
                }
              })
            }
          } else if (res.data.code == 10167) {
            this.$message({
              message: '该部门已存在主管',
              type: 'warning'
            })
          } else if (res.data.code == 10500) {
            this.$message({
              message: '已存在该手机号',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 批量删除
    piliang_shanchu () {
      const date = this.multipleSelection
      let list = []
      if (date.length != 0) {
        for (let i = 0; i < date.length; i++) {
          console.log(date[i])
          if (date[i].staff_id != undefined) {
            list.push(date[i].staff_id)
          }
        }
        let shanchu_ziji = false
        list.forEach(item => {
          if (item == this.$jichuxinxi().user_id) {
            shanchu_ziji = true
          }
        })
        list = list.join(',')
        console.log(JSON.stringify({
          data: {
            id: date.map(item => item.id).join(','),
            staff_ids: list,
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }))
        if (!shanchu_ziji) {
          delete_staff({
            data: {
              id: date.map(item => item.id).join(','),
              staff_ids: list.length != 0 ? list : '',
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10151) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.jichu()
              if (this.bumen_num == 1) {
                query_dept_staff_list({
                  data: {
                    ent_id: this.$ent_id(),
                    user_id: this.$jichuxinxi().user_id,
                    dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                    active: '1'
                  }
                }).then(ras => {
                  if (ras.data.code == 10149) {
                    const listdata = JSON.parse(ras.data.body.data)
                    console.log(99999, listdata)
                    if (this.bumen_num == 0) {
                      this.tableData = listdata
                    } else if (this.bumen_num == 1) {
                      const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                      if (bumen_zhuguan_xinxi != null) {
                        if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                          for (let a = 0; a < listdata.length; a++) {
                            if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                              this.$set(listdata[a], 'zhuguan', '1')
                            }
                          }
                        }
                      } else {
                        this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                        this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                      }

                      console.log(99999, listdata)
                      this.zuzhijiagou_bumenrenyuan = listdata
                    }
                  } else if (ras.data.code == 10150) {
                  }
                })
              }
            } else if (res.data.code == 10152) {
              this.$message({
                message: '删除失败',
                type: 'error'
              })
            } else if (res.data.code == 12345) {
              this.$message({
                message: '选中人员已有数据，不得删除',
                type: 'warning'
              })
            }
          })
        } else {
          this.$message({
            message: '不能删除自己',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请选择要删除的人员',
          type: 'warning'
        })
      }
    },
    // 进批量导入
    jin_piliangdaoru () {
      sessionStorage.setItem('pc_mlbb_piliangdaoru', '部门')
      this.$router.push('/imports')
    },
    // 点击编辑部门
    bianji_bumen (i) {
      console.log(i)
      // this.dangqian_bumen_list=this.mianbao_list[this.mianbao_list.length-1]
      this.dangqian_bu_men = true
      this.tianjiaa = true
      this.zuihou_bumen = i.name
      this.zuihou_bumen_id = i.parent_id
      this.xiugai_bumen = true
      // this.xin_shifou=
      // if(i.child_flag==1){
      //     this.xin_shifou='是'
      // }else{
      //     this.xin_shifou='否'
      // }
      if (i.aim_prop == 0) {
        this.mubiao_num = '1'
      } else if (i.aim_prop == 1) {
        this.mubiao_num = '0'
      }
      this.zuihou_bumen_zhuguan = i.leader_name
      this.zhuguan_xinxi = {
        name: i.leader_name,
        staff_id: i.leader_id
      }
    },
    // 点击编辑部门里的确定
    box_sure_bumenxinxi () {
      if (this.zuihou_bumen != '') {
        // let shifou=''
        // if(this.xin_shifou=='是'){
        //     shifou='1'
        // }else if(this.xin_shifou='否'){
        //     shifou='0'
        // }
        let mubiao = ''
        if (this.mubiao_num == 0) {
          mubiao = '1'
        } else if (this.mubiao_num == 1) {
          mubiao = '0'
        }
        console.log(this.zhuguan_xinxi)
        set_ent_dept_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            aim_prop: mubiao,
            dept_name: this.zuihou_bumen,
            // child_flag:'1',
            active: this.acta,
            dept_leader_id: (this.zhuguan_xinxi.staff_id != undefined && this.zhuguan_xinxi.staff_id.length != 0) ? this.zhuguan_xinxi.staff_id : null,
            dept_id: this.zuihou_bumen_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10099) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            // this.jichu()
            // this.mianbao_list[this.mianbao_list.length-1]['leader_name']=this.zhuguan_xinxi.name!=undefined?this.zhuguan_xinxi.name:''
            // this.mianbao_list[this.mianbao_list.length-1]['leader_id']=this.zhuguan_xinxi.staff_id!=undefined?this.zhuguan_xinxi.staff_id:''
            this.$set(this.mianbao_list[this.mianbao_list.length - 1], 'leader_name', this.zhuguan_xinxi.name != undefined ? this.zhuguan_xinxi.name : '')
            this.$set(this.mianbao_list[this.mianbao_list.length - 1], 'leader_id', this.zhuguan_xinxi.staff_id != undefined ? this.zhuguan_xinxi.staff_id : '')
            // ,aim_prop:i.aim_prop!=undefined?i.aim_prop:'0'
            this.mianbao_list[this.mianbao_list.length - 1].aim_prop = mubiao
            this.$set(this.mianbao_list[this.mianbao_list.length - 1], 'name', this.zuihou_bumen)
            this.$set(this.mianbao_list[this.mianbao_list.length - 1], 'parent_id', this.zuihou_bumen_id)
            console.log(this.mianbao_list)
            this.bumen_liebiao()
            if (this.bumen_num == 1) {
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  user_id: this.$jichuxinxi().user_id,
                  dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                  active: this.acta
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                  // this.mianbao_list[this.mianbao_list.length-1].name=this.zuihou_bumen
                  this.dangqian_bu_men = false
                } else if (ras.data.code == 10150) {
                }
              })
            } else {
              this.dangqian_bu_men = false
            }
          } else if (res.data.code == 10100) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          } else if (res.data.code == 11400) {
            this.$message({
              message: '请先将人员和部门目标调整至0，取消售卖规则，再取消目标或停用部门',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 点击编辑部门里的删除
    // box_shanchu_bumenxinxi(){
    //     delete_dept_info({data:{
    //         ent_id:this.$ent_id(),
    //         user_id:this.$jichuxinxi().user_id,
    //         parent_id:this.mianbao_list[this.mianbao_list.length-1].parent_id
    //     }}).then(res=>{
    //         console.log(res)
    //         if(res.data.code==10163){
    //             this.$message({
    //                 message: '删除成功',
    //                 type: 'success'
    //             });
    //             this.jichu()
    //             query_dept_staff_list({data:{
    //                 ent_id:this.$ent_id(),
    //                 user_id:this.$jichuxinxi().user_id
    //             }}).then(ras=>{
    //                 if(ras.data.code==10149){
    //                     let listdata=JSON.parse(ras.data.body.data)
    //                     console.log(99999,listdata)
    //                     this.zuzhijiagou_bumenrenyuan=listdata
    //                     this.mianbao_list[this.mianbao_list.length-1].name=this.zuihou_bumen
    //                 }else if(ras.data.code==10150){
    //                 }
    //             })
    //             this.dangqian_bu_men=false
    //         }else if(res.data.code==10164){
    //             this.$message({
    //                 message: '删除失败',
    //                 type: 'error'
    //             });
    //         }
    //     })
    // },
    // 点击添加部门
    dianji_tianjiabumen () {
      this.zuihou_bumen = ''
      this.dangqian_bu_men = true
      this.tianjiaa = false
    },
    box_sure_tianjia_bumenxinxi () {
      if (this.zuihou_bumen != '') {
        // let shifou=''
        // if(this.xin_shifou=='是'){
        //     shifou='1'
        // }else if(this.xin_shifou='否'){
        //     shifou='0'
        // }
        let mubiao = ''
        if (this.mubiao_num == 0) {
          mubiao = '1'
        } else if (this.mubiao_num == 1) {
          mubiao = '0'
        }
        set_ent_dept_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
            aim_prop: mubiao,
            dept_name: this.zuihou_bumen,
            // child_flag:'1',
            active: this.acta
          // dept_leader_id:this.zhuguan_xinxi.staff_id,
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10099) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.jichu()
            // query_dept_staff_list({data:{
            //     ent_id:this.$ent_id(),
            //     user_id:this.$jichuxinxi().user_id,
            //     active:'1'
            // }}).then(ras=>{
            //     if(ras.data.code==10149){
            //         let listdata=JSON.parse(ras.data.body.data)
            //         console.log(99999,listdata)
            //         this.zuzhijiagou_bumenrenyuan=listdata
            //         this.mianbao_list[this.mianbao_list.length-1].name=this.zuihou_bumen
            //     }else if(ras.data.code==10150){
            //     }
            // })
            this.dangqian_bu_men = false
            this.bumen_liebiao()
            if (this.mianbao_list.length != 1) {
              this.xiugai_gengxin_bumen('1')
            }
          } else if (res.data.code == 10100) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          } else if (res.data.code == 11400) {
            this.$message({
              message: '请先将人员目标和部门目标调整至0，再取消目标',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写要添加的部门',
          type: 'warning'
        })
      }
    },
    xiugai_gengxin_bumen (num) {
      //
      // user_id:this.$jichuxinxi().user_id,
      // aim_prop:this.mianbao_list[this.mianbao_list.length-1].aim_prop,
      // dept_name:this.mianbao_list[this.mianbao_list.length-1].name,
      // active:'1',
      // dept_leader_id:this.zhuguan_xinxi.staff_id!=undefined&&this.zhuguan_xinxi.staff_id.length!=0?this.zhuguan_xinxi.staff_id:null,
      update_dept_child_flag({
        data: {
          ent_id: this.$ent_id(),
          child_flag: num,
          dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id
        }
      }).then(res => {
        console.log(res)
      })
    },
    // 批量离职
    piliang_lizhi () {
      const date = this.multipleSelection
      let list = []
      if (date.length != 0) {
        for (let i = 0; i < date.length; i++) {
          console.log(date[i])
          list.push(date[i].staff_id)
        }
        let shanchu_ziji = false
        list.forEach(item => {
          if (item == this.$jichuxinxi().user_id) {
            shanchu_ziji = true
          }
        })
        list = list.join(',')
        if (!shanchu_ziji) {
          update_staff_active_batch({
            data: {
              ent_id: this.$ent_id(),
              active: '0',
              staff_ids: list,
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10211) {
              this.$message({
                message: '离职成功',
                type: 'success'
              })
              if (this.bumen_num == 0) {
                this.jichu()
              } else if (this.bumen_num == 1) {
                query_dept_staff_list({
                  data: {
                    ent_id: this.$ent_id(),
                    dept_id: this.bumen_num == 0 ? null : this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                    user_id: this.$jichuxinxi().user_id,
                    active: '1'
                  }
                }).then(ras => {
                  console.log(ras)
                  if (ras.data.code == 10149) {
                    const listdata = JSON.parse(ras.data.body.data)
                    console.log(listdata)
                    // if(this.bumen_num==0){
                    //     this.tableData=listdata
                    // }else if(this.bumen_num==1){
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                    // }
                  } else if (ras.data.code == 10150) {
                  }
                })
              }
            } else if (res.data.code == 10212) {
              this.$message({
                message: '离职失败',
                type: 'error'
              })
            } else if (res.data.code == 11400) {
              this.$message({
                message: '该人员已有目标，不能离职',
                type: 'error'
              })
            }
          })
        } else {
          this.$message({
            message: '不能离职自己',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请选择要离职的人员',
          type: 'warning'
        })
      }
    },
    // 批量离职
    lizhi_renyuan () {

    },
    // 批量在职
    piliang_zaizhi () {
      const date = this.multipleSelection
      let list = []
      if (date.length != 0) {
        for (let i = 0; i < date.length; i++) {
          console.log(date[i])
          list.push(date[i].staff_id)
        }
        list = list.join(',')
        update_staff_active_batch({
          data: {
            ent_id: this.$ent_id(),
            active: '1',
            staff_ids: list,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10211) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.jichu()
          } else if (res.data.code == 10212) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          } else if (res.data.code == 11400) {
            this.$message({
              message: '该人员已有目标，不能离职',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请选择要在职的人员',
          type: 'warning'
        })
      }
    },
    // 模糊搜索
    keyu () {

    },
    dakai_xuanzezhuguan () {
      this.qiye_bumenb = true
    },
    // 选择主管理员确定
    xuanze_zhuguan_sure () {
      if (this.zhuguan_xinxi != '') {
        console.log(this.zhuguan_xinxi)
        this.zuihou_bumen_zhuguan = this.zhuguan_xinxi.name
        this.qiye_bumenb = false
      } else {
        this.$message({
          message: '你还没有选择相应的主管',
          type: 'warning'
        })
      }
    },
    // 目标选择是否
    mubiao_shifou_num (index) {
      this.mubiao_num = index
    },
    tianjia_tingyong_bumen () {
      this.ting_bumen = true
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          active: '1'
        }
      }).then(res => {
        console.log(1111111, res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(item => {
            this.$set(item, 'zhi', false)
          })
          this.qiye_bumen_list_ting = date
        } else if (res.data.code == 10169) {
        }
      })
    },
    // tupian_dianji_ting(index){

    // },
    // 进入导出
    jin_daochu () {
      sessionStorage.setItem('pc_pc_pc_daochu', '人员')
      this.$router.push('/exportb')
    },
    // 停用部门里停用部门停用按钮里的多选框
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      // let list=this.weitingyong_bumen
      // console.log(e)
      // if(e){
      //     list.push(i)
      // }else{
      //     for(let a=0;a<list.length;a++){
      //         if(i.dept_name==list[a].dept_name){
      //             list[a].splice(a,1)
      //         }
      //     }
      // }
      // this.weitingyong_bumen=list
      // console.log(list)
    },
    // 选择好未停用的部门进行停用
    xuanze_weitingyong_sure () {
      let list = []
      for (let i = 0; i < this.qiye_bumen_list_ting.length; i++) {
        if (this.qiye_bumen_list_ting[i].zhi) {
          list.push(this.qiye_bumen_list_ting[i].dept_id)
        }
        // list.push(this.weitingyong_bumen[i].dept_id)
      }
      // qiye_bumen_list_ting
      list = list.join(',')
      console.log(list)
      update_dept_active_batch({
        data: {
          ent_id: this.$ent_id(),
          active: '0',
          dept_ids: list,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10209) {
          this.$message({
            message: '停用成功',
            type: 'success'
          })
          for (let i = 0; i < this.weitingyong_bumen.length; i++) {
            this.$refs.quana[i].checked = false
            console.log(this.$refs.quana[i])
          }
          this.weitingyong_bumen = []
          this.bumen_liebiao()
          this.ting_bumen = false
        } else if (res.data.code == 10210) {} else if (res.data.code == 10247) {
          this.$message({
            message: '该部门下存在人员，无法停用该部门',
            type: 'warning'
          })
        } else if (res.data.code == 11400) {
          this.$message({
            message: '该部门存在目标，不能停用',
            type: 'warning'
          })
        } else if (res.data.code == 11500) {
          this.$message({
            message: '有产品规则存在，不能停用',
            type: 'warning'
          })
        }
      })
    },
    tupian_dianjiaa () {
      this.indeb = '3'
      query_ent_dept_list_aim({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.tree_bumen = date
        } else if (res.data.code == 10169) {}
      })
    },
    // 目标  取消添加目标
    quxiao_tianjia_mubiao (date) {
      console.log(date)

      if (date.aim_prop == '0' || date.aim_prop == '') {
        this.mubiao_shifou_id = '1'
      } else if (date.aim_prop == '1') {
        this.mubiao_shifou_id = '0'
      }
      set_ent_dept_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          aim_prop: this.mubiao_shifou_id,
          dept_id: date.dept_id,
          dept_name: date.label,
          active: this.acta,
          dept_leader_id: this.zhuguan_xinxi.staff_id != undefined ? this.zhuguan_xinxi.staff_id : null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10099) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.tupian_dianjiaa()
        } else if (res.data.code == 10100) {
          this.$message({
            message: '修改失败',
            type: 'error'
          })
        } else if (res.data.code == 11400) {
          this.$message({
            message: '请先将人员目标和部门目标调整至0，再取消目标',
            type: 'error'
          })
        }
      })
    },
    // 停用启用目标
    tingyong_qiyong_mubiao (data) {
      console.log(data)
      let active = ''
      if (data.active == 0) {
        active = '1'
      } else if (data.active == 1) {
        active = '0'
      }
      update_dept_active_batch({
        data: {
          ent_id: data.ent_id,
          active: active,
          dept_ids: data.dept_id,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10209) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.tupian_dianjiaa()
        } else if (res.data.code == 10210) {} else if (res.data.code == 10247) {
          this.$message({
            message: '该部门下存在人员，无法停用该部门',
            type: 'warning'
          })
        } else if (res.data.code == 11400) {
          this.$message({
            message: '该部门存在目标，不能停用',
            type: 'warning'
          })
        } else if (res.data.code == 11500) {
          this.$message({
            message: '有产品规则存在，不能停用',
            type: 'warning'
          })
        }
      })
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    // 20200408重构    5/14改了个屁？
    renyuan_bumen_qiehuan (index) {
      if (index == 0 && this.bumen_num != 0) {
        this.dangqian_bumen = false // 不知道有啥用
        this.jichu()
      } else if (index == 1 && this.bumen_num != 1) {
        // this.bumen_liebiao()
        this.mianbao_list.splice(1, this.mianbao_list.length)
        this.indeb = '0'
        query_ent_dept_list_of_all({
          data: {
            ent_id: this.$ent_id(),
            parent_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
            user_id: this.$jichuxinxi().user_id,
            active: '1'
          // this.acta
          }
        }).then(res => {
          // console.log(11111,res)
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            console.log('部门', date)
            date.map(item => { this.$set(item, 'zhi', false) })
            this.qiye_bumen_list = date
            this.qiye_bumen_list_ting = date
            if (this.mianbao_list.length == 1) {
              this.dangqian_bumen = false
            }
            if (this.bumen_num == 1) {
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                  user_id: this.$jichuxinxi().user_id,
                  active: this.acta + ''
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id != undefined && this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                } else if (ras.data.code == 10150) {
                }
              })
            }
          } else if (res.data.code == 10169) {
          }
        })
      }
      this.bumen_num = index
    },
    // 查询企业已注册人数
    qiye_zhuce_num () {
      query_ent_staffNum_registerNum({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_dangqian_zhuce_num = date
        } else if (res.data.code == 500) {}
      })
    },
    // 部门的接口
    bumen_liebiao (name) {
      if (name != undefined && name == 'renyuan_xiugai') {

      } else {
        if (this.indeb == 1) {
          query_ent_dept_list2({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              active: this.indeb == 1 ? '0' : '1'
            }
          }).then(res => {
            console.log(1111111, res)
            if (res.data.code == 10168) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)

              if (date.length != 0) {
                this.xiugai_gengxin_bumen('1')
              } else {
                this.xiugai_gengxin_bumen('0')
              }
              //
              date.map(item => { this.$set(item, 'zhi', false) })
              this.qiye_bumen_list = date
              console.log(this.qiye_bumen_list)
            } else if (res.data.code == 10168) {
            }
          })
        } else if (this.indeb == 0) {
          // query_ent_dept_list  8.9部门数量问题  更改
          query_ent_dept_list_of_all({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              active: this.indeb == 1 ? '0' : '1',
              parent_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id
            }
          }).then(res => {
            console.log(1111111, res)
            if (res.data.code == 10168) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              if (date.length != 0) {
                this.xiugai_gengxin_bumen('1')
              } else {
                this.xiugai_gengxin_bumen('0')
              }
              //
              date.map(item => { this.$set(item, 'zhi', false) })
              this.qiye_bumen_list = date
              console.log(this.qiye_bumen_list)
            } else if (res.data.code == 10168) {
            }
          })
        }
      }
    },
    dakai_bumen () {
      // this.mianbao_list.splice(1,this.mianbao_list.length)
      // this.bumen_liebiao()
      this.qiye_bumen = true
      query_ent_dept_list_aim_not({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.bumen_tree = date
        } else if (res.data.code == 10169) {}
      })
    },
    // 调整部门里选择部门    确定取消
    tiaozheng_queding () {
      console.log(this.$refs.zuzhi_jiagou_tree.getCheckedNodes())
      this.kan_list.bu_men = this.$refs.zuzhi_jiagou_tree.getCheckedNodes().map(i => i.label).join(',')
      this.tiaozheng_bumen_id = this.$refs.zuzhi_jiagou_tree.getCheckedNodes().map(i => i.dept_id).join(',')
      this.qiye_bumen = false
      // if(this.inde!=-1){
      //     console.log(4444,this.qiye_bumen_list[this.inde])
      //     =this.qiye_bumen_list[this.inde].dept_id
      //     this.kan_list.bu_men=this.qiye_bumen_list[this.inde].dept_name
      //     this.qiye_bumen=false
      //     this.inde=-1
      // }else{
      //     this.$message({
      //         message: '请选择您要调整的部门',
      //         type: 'warning'
      //     });
      // }
    },
    tiaozheng_quxiao () {
      this.qiye_bumen = false
    },
    tiaozheng_renyuan_bumen () {
      if (this.multipleSelection.length != 0) {
        this.zhanshi_tiaozheng_renyuan_bumen = true
        query_ent_dept_list_aim_not({
          data: {
            ent_id: this.$ent_id(),
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.renyuan_tiaozheng_bumen = date
          } else if (res.data.code == 10169) {}
        })
      } else {
        this.$message({
          message: '请选择您要调整的人员',
          type: 'warning'
        })
      }
    },
    renyuan_tiaozheng_queding () {
      if (this.$refs.tiaozheng_renyuan_tree.getCheckedNodes().length != 0) {
        console.log(JSON.stringify({
          data: {
            dept_id: this.$refs.tiaozheng_renyuan_tree.getCheckedNodes().map(item => item.dept_id).join(','),
            staff_id: this.multipleSelection.map(item => item.staff_id).join(','),
            current_dept_id: this.multipleSelection.map(item => item.dept_id).join(',').length != 0 ? this.multipleSelection.map(item => item.dept_id).join(',') : null,
            ent_id: this.$ent_id()
          }
        }))
        //
        batch_set_dept_for_many_staff({
          data: {
            dept_id: this.$refs.tiaozheng_renyuan_tree.getCheckedNodes().map(item => item.dept_id).join(','),
            staff_id: this.multipleSelection.map(item => item.staff_id).join(','),
            current_dept_id: this.multipleSelection.map(item => item.dept_id).join(',').length != 0 ? this.multipleSelection.map(item => item.dept_id).join(',') : null,
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10159) {
            this.bumen_liebiao('renyuan_xiugai')
            if (this.mianbao_list[this.mianbao_list.length - 1].leader_id != undefined && this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
              this.multipleSelection.forEach(ite => {
                if (ite.staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                  set_ent_dept_info({
                    data: {
                      ent_id: this.$ent_id(),
                      user_id: this.$jichuxinxi().user_id,
                      aim_prop: this.mianbao_list[this.mianbao_list.length - 1].aim_prop,
                      dept_name: this.mianbao_list[this.mianbao_list.length - 1].name,
                      active: '1',
                      dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id
                    }
                  }).then(res => {
                    console.log(res)
                  })
                  this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                  this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                  this.zhuguan_xinxi = {}
                  this.zuihou_bumen_zhuguan = ''
                }
              })
            }
            console.log(this.mianbao_list)

            if (this.bumen_num == 1) {
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  dept_id: this.mianbao_list.length == 1 ? this.mianbao_list[0].parent_id : this.mianbao_list[this.mianbao_list.length - 1].parent_id,
                  user_id: this.$jichuxinxi().user_id,
                  active: this.acta + ''
                }
              }).then(ras => {
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  if (this.bumen_num == 0) {
                    this.tableData = listdata
                  } else if (this.bumen_num == 1) {
                    const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
                    if (bumen_zhuguan_xinxi != null) {
                      if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                        for (let a = 0; a < listdata.length; a++) {
                          if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                            this.$set(listdata[a], 'zhuguan', '1')
                          }
                        }
                      }
                    } else {
                      this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                      this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
                    }

                    console.log(99999, listdata)
                    this.zuzhijiagou_bumenrenyuan = listdata
                  }
                } else if (ras.data.code == 10150) {
                }
              })
            }
            this.jichu()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.zhanshi_tiaozheng_renyuan_bumen = false
          } else if (res.data.code == 10160) {}
        })
      }
    },
    handleClick (data, checked, node) {
      // if(checked){
      //     this.$refs.tiaozheng_renyuan_tree.setCheckedNodes([data]);
      // }
    },
    dianji_xuanzhong_bumen (i) {
      i.zhi = !i.zhi
    },
    shangyi (i, index) {
      console.log(i)
      console.log(index)
      // let qiye_list=this.qiye_bumen_list.map(item=>item)
      if (index != 0) {
        this.qiye_bumen_list.splice(index, 1)
        this.qiye_bumen_list.splice(Number(index) - 1, 0, i)
        this.tiaozheng_shunxu(this.qiye_bumen_list)
      }
    },
    tiaozheng_shunxu (i) {
      console.log(i,'con')
      const list = []
      for (let a = 0; a < i.length; a++) {
        list.push({
          dept_id: i[a].dept_id,
          sort: a + 1
        })
      }
      update_dept_sort({
        data: {
          ent_id: this.$ent_id(),
          list: list
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: '调整成功',
            type: 'success'
          })
        } else if (res.data.code == 500) {}
      })
    },
    xiayi (i, index) {
      if (index != Number(this.qiye_bumen_list.length) - 1) {
        this.qiye_bumen_list.splice(index, 1)
        this.qiye_bumen_list.splice(index + 1, 0, i)
        this.tiaozheng_shunxu(this.qiye_bumen_list)
      }
    },
    // 点击当前总人数
    dangqian_zongren_shu () {
      this.jichu()
    },
    // 未注册人数
    wei_zhuce_renshu () {
      query_staff_list_register_not({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.tableData = date
          this.$message({
            message: '切换成功',
            type: 'success'
          })
        } else if (res.data.code == 500) {}
      })
    },
    chaxun_name () {
      if (this.bumen_num == 1 && this.sousuo_renyuan_name.length == 0) {
        // query_ent_dept_list({data:{
        //     ent_id:this.$ent_id(),
        //     parent_id:i.dept_id,
        //     user_id:this.$jichuxinxi().user_id,
        //     active:this.acta
        // }}).then(res=>{
        //     if(res.data.code==10168){
        //         let date=JSON.parse(res.data.body.data)
        //         console.log(date)
        //         date.map(item=>{this.$set(item,'zhi',false)})
        //         this.qiye_bumen_list=date
        //         this.mianbao_list.push({name:i.dept_name,parent_id:i.dept_id,leader_name:i.leader_name!=undefined?i.leader_name:'',leader_id:i.dept_leader_id!=undefined?i.dept_leader_id:'',aim_prop:i.aim_prop!=undefined?i.aim_prop:'0'})
        //         this.inde=-1
        //         if(this.bumen_num==1){
        query_dept_staff_list({
          data: {
            ent_id: this.$ent_id(),
            dept_id: this.mianbao_list[this.mianbao_list.length - 1].parent_id,
            user_id: this.$jichuxinxi().user_id,
            active: '1'
          }
        }).then(ras => {
          if (ras.data.code == 10149) {
            const listdata = JSON.parse(ras.data.body.data)
            if (this.bumen_num == 0) {
              this.tableData = listdata
            } else if (this.bumen_num == 1) {
              const bumen_zhuguan_xinxi = JSON.parse(ras.data.body.leader_info)
              if (bumen_zhuguan_xinxi != null) {
                if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                  for (let a = 0; a < listdata.length; a++) {
                    if (listdata[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                      this.$set(listdata[a], 'zhuguan', '1')
                    }
                  }
                }
              } else {
                this.mianbao_list[this.mianbao_list.length - 1].leader_id = ''
                this.mianbao_list[this.mianbao_list.length - 1].leader_name = ''
              }
              console.log(99999, listdata)
              this.zuzhijiagou_bumenrenyuan = listdata
            }
          } else if (ras.data.code == 10150) {
          }
        })
        // }
        //     }else if(res.data.code==10169){
        //     }
        // })
      } else {
        query_ent_staff_list({
          data: {
            ent_id: this.$ent_id(),
            staff_name: this.sousuo_renyuan_name.length != 0 ? this.sousuo_renyuan_name : null,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10149) {
            const date = JSON.parse(res.data.body.data)
            console.log(this.bumen_num)
            if (this.bumen_num == 0) {
              this.tableData = date
            } else if (this.bumen_num == 1) {
              if (this.mianbao_list[this.mianbao_list.length - 1].leader_id.length != 0) {
                for (let a = 0; a < date.length; a++) {
                  if (date[a].staff_id == this.mianbao_list[this.mianbao_list.length - 1].leader_id) {
                    this.$set(date[a], 'zhuguan', '1')
                  }
                }
              }
              this.zuzhijiagou_bumenrenyuan = date
            }
            console.log(this.tableData)
          } else if (res.data.code == 10150) {}
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.detsta {
    height:100%;
    width:100%;
    position: relative;
    .dqbm{
        position: absolute;
        left: 0.31rem;
        top:0.06rem;
        display: flex;
        align-items: center;
        p{
            color:#1A2533;
            font-size: 0.18rem;
        }
        div{
            height:0.25rem;
            width:0.49rem;
            border-radius: 0.02rem;
            border:0.01rem solid #888;
            font-size: 0.12rem;
            color:#888888;
            line-height: 0.25rem;
            text-align: center;
            margin-left: 0.1rem;
            cursor:pointer;
        }
    }
    .title{
        margin: 0 auto;
        margin-top: 0.24rem;
        display: flex;
        justify-content: center;
        margin-bottom: 0.3rem;
        p{
            height:0.36rem;
            width:1.2rem;
            font-size: 0.14rem;
            line-height: 0.36rem;
            text-align: center;
            cursor:pointer;
            color:#8d52e3;
            &:nth-child(1){
                border-radius: 0.02rem 0 0 0.02rem;
            }
            &:nth-child(2){
                border-radius: 0 0.02rem 0.02rem 0;
            }
        }
        .p1{
            background: #8d52e3;
            border:0.01rem solid #8d52e3;
            color:#fff;
        }
        .p2{
            border:0.01rem solid #8d52e3;
        }
    }
    .box{
        padding: 0 0.31rem;
        position: relative;
        .p1{
            // font-size: 0.18rem;
                    font-size: 0.14rem;
            color:#1A2533;
            margin-bottom: 0.1rem;
            span{
                cursor: pointer;
                color: #409EFF;
            }
        }
        .tit_center{
            position: absolute;
            left:50%;
            top:0;
            margin-left: -0.6rem;
          display: flex;
          align-items: center;
          div{
            display: flex;
            align-items: center;
            margin-right: 0.27rem;
            cursor:pointer;
            img{
              height:0.18rem;
              width:0.18rem;
              margin-right: 0.04rem;
            }
            p{
              font-size: 0.12rem;
              color:#4C4A4D;
            }
          }
        }
        .box_nei{
            height:0.42rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.12rem;
            background:#E7E8EB;
            div{
                display: flex;
                justify-content: left;
                align-items: center;
                p{
                    margin-right:0.12rem;
                }
            }
            p{
                // width:0.75rem;
                // height:0.25rem;
                padding: 0 0.12rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
            }
            .sousuo_box{
                border:none;
                padding: 0;
                background:#E7E8EB;
                display: flex;
                align-items: center;
                position: relative;
                .el-icon-search{
                    font-size: 0.14rem;
                    position: absolute;
                    top:0.08rem;
                    left:0.08rem;
                }
                input{
                    height:0.27rem;
                    padding: 0 0.3rem;
                    border:0.01rem solid #A9A9A9;
                    border-radius: 0.03rem;
                }
                .el-icon-circle-close{
                    font-size: 0.14rem;
                    position: absolute;
                    right:0.08rem;
                    top:0.08rem;
                    display: none;
                }
                &:hover{
                    .el-icon-circle-close{
                        display: block;
                    }
                }
            }
        }
    }
    .box2{
        padding: 0 0.31rem;
        .box2_mianbaoxie{
            display: flex;
            // align-items: center;
            width:3.4rem;
            flex-wrap: wrap;
            // justify-content: left;
            margin-bottom: 0.1rem;
            div{
                display: flex;
                align-items: center;
                justify-content: left;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#8d52e3;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .tit_center{
            position: absolute;
            left:50%;
            top:0.68rem;
            margin-left: -1rem;
            display: flex;
            align-items: center;
            div{
                display: flex;
                align-items: center;
                margin-right: 0.27rem;
                cursor:pointer;
                img{
                height:0.18rem;
                width:0.18rem;
                margin-right: 0.04rem;
                }
                p{
                font-size: 0.12rem;
                color:#4C4A4D;
                }
            }
        }
        .box_nei{
            height:0.42rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.12rem;
            background:#E7E8EB;
            div{
                display: flex;
                justify-content: left;
                align-items: center;
                p{
                    margin-right:0.12rem;
                }
            }
            p{
                // width:0.75rem;
                // height:0.25rem;
                padding: 0 0.12rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
            }
            .sousuo_box{
                border:none;
                padding: 0;
                background:#E7E8EB;
                display: flex;
                align-items: center;
                position: relative;
                .el-icon-search{
                    font-size: 0.14rem;
                    position: absolute;
                    top:0.08rem;
                    left:0.08rem;
                }
                input{
                    height:0.27rem;
                    padding: 0 0.3rem;
                    border:0.01rem solid #A9A9A9;
                    border-radius: 0.03rem;
                }
                .el-icon-circle-close{
                    font-size: 0.14rem;
                    position: absolute;
                    right:0.08rem;
                    top:0.08rem;
                    display: none;
                }
                &:hover{
                    .el-icon-circle-close{
                        display: block;
                    }
                }
            }
        }
        .box2_liebiao{
            min-height:1.8rem;
            ul{
                padding: 0.15rem 0;
                li{
                    display: flex;
                    height:0.4rem;
                    justify-content: space-between;
                    align-items: center;
                    // padding: 0 0.35rem;
                    color:#1A2533;
                    font-size: 0.12rem;
                    font-weight: bold;
                    &:hover{
                        background: #f5f7fa;
                    }
                    // .img1{
                    //     height:0.11rem;
                    //     width:0.2rem;
                    // }
                    input{
                        -webkit-appearance: none;
                        width: 0.15rem!important;
                        height: 0.15rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.32rem 0 0.1rem!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/kaung_kongxin.png) no-repeat;
                        &:checked {
                            background: url(../../assets/kuang_duigou.png) no-repeat;
                            background-size: 100%;
                        }
                        background-size: 100%;
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    img{
                        width: 0.15rem!important;
                        height: 0.15rem!important;
                        cursor: pointer;
                        margin: -0.03rem 0.32rem 0 0.1rem!important;
                        display: inline-block;
                    }
                    .box2_liebiao_tit{
                        font-size: 0.12rem;
                        // color:#A5C6FD;
                        margin-left: 0.28rem;
                        cursor:pointer;
                        display: flex;
                        align-items: center;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        p{
                            display: flex;
                            color: darkgrey;
                            align-items: center;
                            font-size: 0.12rem;
                            margin-left: 0.28rem;
                            cursor:pointer;
                            &:hover{
                                color: rgb(52, 53, 51);
                            }
                        }
                    }
                }
            }
        }
    }
    .tiao_bumen{
        height:3.79rem;
        background: #E7E8EB;
        margin: 0 auto;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .mianbao_box{
            margin-left: 0.35rem;
            padding-top:0.19rem;
            display: flex;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#8d52e3;
                }
                .p1{
                    color:#4C4A4D;
                }
            }
        }
        ul{
            padding-top: 0.21rem;
            li{
                display: flex;
                height:0.4rem;
                justify-content: space-between;
                align-items: center;
                padding: 0 0.35rem;
                cursor:pointer;
                input{
                    -webkit-appearance: none;
                    width: 0.18rem!important;
                    height: 0.17rem!important;
                    display: inline-block;
                    margin: -0.03rem 0.05rem 0 0!important;
                    cursor: pointer;
                    vertical-align: middle;
                    background:url(../../assets/noccc.png) no-repeat;
                    &:checked {
                        background: url(../../assets/okblue.png) no-repeat;
                    }
                    outline-color:none;
                    outline-style :none;
                    outline-width:none;
                }
                &:hover{
                    background: #ccc;
                }
                div{
                    display: flex;
                    align-items: center;
                    // img{
                    //     display:block;
                    //     margin-right: 0.09rem;
                    // }
                    img{
                        width: 0.15rem!important;
                        height: 0.15rem!important;
                        cursor: pointer;
                        margin: -0.03rem 0.32rem 0 0.1rem!important;
                        display: inline-block;
                    }
                }
                .img1{
                    height:0.11rem;
                    width:0.2rem;
                }
            }
        }
        .el-tree{
            background:none;
        }
    }
    // .tiao_bumenb{
    //     display: flex;
    //     align-items: center;
    //     justify-content:center;
    //     .xuanze,.yixuan{
    //         height:3.54rem;
    //         width:3.85rem;
    //         background: #E7E8EB;
    //         margin: 0 0.11rem;
    //         margin-top: 0.2rem;
    //         position: relative;
    //         .tit{
    //             position: absolute;
    //             left:0;
    //             top:-0.3rem;
    //             font-size: 0.14rem;
    //             color:#1A2533;
    //         }
    //         .xuanze_sousuo{
    //             position: relative;
    //             i{
    //                 position: absolute;
    //                 top:0.09rem;;
    //                 left: 0.32rem;
    //             }
    //             input{
    //                 height:0.3rem;
    //                 width:2.74rem;
    //                 border:none;
    //                 background:#fff;
    //                 padding: 0 0.33rem;
    //                 margin: 0 auto;
    //                 display: block;
    //                 margin-top: 0.15rem;
    //                 border-radius:0.02rem;
    //             }
    //         }
    //         .mianbaoxie{
    //             display: flex;
    //             align-items: center;
    //             justify-content: left;
    //             margin-bottom: 0.1rem;
    //             margin-left: 0.23rem;
    //             margin-top:0.12rem;
    //             div{
    //                 display: flex;
    //                 align-items: center;
    //                 cursor:pointer;
    //                 p{
    //                     font-size: 0.14rem;
    //                 }
    //                 .p2{
    //                     color:#8d52e3;
    //                 }
    //                 .p1{
    //                     color:#1A2533;
    //                 }
    //             }
    //         }
    //         .quanxuan{
    //             display: flex;
    //             align-items: center;
    //             margin-left: 0.24rem;
    //             input{
    //                 -webkit-appearance: none;
    //                 width: 0.18rem!important;
    //                 height: 0.17rem!important;
    //                 display: inline-block;
    //                 margin: -0.03rem 0.05rem 0 0!important;
    //                 cursor: pointer;
    //                 vertical-align: middle;
    //                 background:url(../../assets/noccc.png) no-repeat;
    //                 &:checked {
    //                     background: url(../../assets/okblue.png) no-repeat;
    //                 }
    //                 outline-color:none;
    //                 outline-style :none;
    //                 outline-width:none;
    //             }
    //             span{
    //                 color:#1A2533;
    //                 font-size: 0.12rem;
    //             }
    //         }
    //         .nei_box{
    //             width:3.45rem;
    //             margin: 0 auto;
    //             max-height: 2.4rem;
    //             overflow:auto;
    //             // overflow-y: hidden;
    //             overflow-y: scroll;
    //             &::-webkit-scrollbar {
    //                 width: 0.19rem;
    //                 height: 0;
    //             }

    //             &::-webkit-scrollbar-thumb {
    //                 border-radius: 0.12rem;
    //                 border: 0.06rem solid rgba(0, 0, 0, 0);
    //                 box-shadow: 0.08rem 0 0 #A5ADB7 inset;
    //             }

    //             &::-webkit-scrollbar-thumb:hover {
    //                 box-shadow: 0.08rem 0 0 #4A4A4A inset;
    //             }
    //             .liebiao{
    //                 width:3.25rem;
    //                 margin: 0 auto;
    //                 ul{
    //                     li{
    //                         display: flex;
    //                         height:0.3rem;
    //                         justify-content: space-between;
    //                         align-items: center;
    //                         color:#1A2533;
    //                         font-size: 0.12rem;
    //                         padding-left: 0.1rem;
    //                         cursor:pointer;
    //                         &:hover{
    //                             background: #eee;
    //                         }
    //                         .img1{
    //                             height:0.11rem;
    //                             width:0.2rem;
    //                         }
    //                         input{
    //                             -webkit-appearance: none;
    //                             width: 0.18rem!important;
    //                             height: 0.17rem!important;
    //                             display: inline-block;
    //                             margin: -0.03rem 0.05rem 0 0!important;
    //                             cursor: pointer;
    //                             vertical-align: middle;
    //                             background:url(../../assets/noccc.png) no-repeat;
    //                             // &:checked {
    //                             //     background: url(../../assets/okblue.png) no-repeat;
    //                             // }
    //                             outline-color:none;
    //                             outline-style :none;
    //                             outline-width:none;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     .yixuan{
    //         .nei_box{
    //             width:3.45rem;
    //             margin: 0 auto;
    //             max-height: 3.5rem;
    //             overflow:auto;
    //             // overflow-y: hidden;
    //             overflow-y: scroll;
    //             &::-webkit-scrollbar {
    //                 width: 0.19rem;
    //                 height: 0;
    //             }

    //             &::-webkit-scrollbar-thumb {
    //                 border-radius: 0.12rem;
    //                 border: 0.06rem solid rgba(0, 0, 0, 0);
    //                 box-shadow: 0.08rem 0 0 #A5ADB7 inset;
    //             }

    //             &::-webkit-scrollbar-thumb:hover {
    //                 box-shadow: 0.08rem 0 0 #4A4A4A inset;
    //             }
    //             .liebiao{
    //                 width:3.25rem;
    //                 padding: 0.2rem 0;
    //                 margin: 0 auto;
    //                 ul{
    //                     li{
    //                         display: flex;
    //                         height:0.3rem;
    //                         justify-content: space-between;
    //                         align-items: center;
    //                         color:#1A2533;
    //                         font-size: 0.12rem;
    //                         padding-left: 0.1rem;
    //                         cursor:pointer;
    //                         &:hover{
    //                             background: #eee;
    //                         }
    //                         .img1{
    //                             height:0.11rem;
    //                             width:0.2rem;
    //                         }
    //                         input{
    //                             -webkit-appearance: none;
    //                             width: 0.18rem!important;
    //                             height: 0.17rem!important;
    //                             display: inline-block;
    //                             margin: -0.03rem 0.05rem 0 0!important;
    //                             cursor: pointer;
    //                             vertical-align: middle;
    //                             background:url(../../assets/noccc.png) no-repeat;
    //                             &:checked {
    //                                 background: url(../../assets/okblue.png) no-repeat;
    //                             }
    //                             outline-color:none;
    //                             outline-style :none;
    //                             outline-width:none;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    .foote{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.21rem;
        p{
            margin: 0 0.23rem;
            height:0.43rem;
            width:1.36rem;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            line-height: 0.43rem;
            text-align: center;
            font-weight: bold;
            cursor:pointer;
        }
        .queding{
            color:#fff;
            background: #8d52e3;
        }
        .quxiao{
            color: #888888;
            border:0.01rem solid #888888;
            background: #fff;
        }
    }
    .tree{
        padding: 0 0.3rem;
        .p1{
            background: #FFB466;
            padding: 0 0.23rem;
            height:0.42rem;
            line-height: 0.42rem;
            font-size: 0.18rem;
            color:#fff;
        }
        .custom-tree-node{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.14rem;
            padding-right: 0.08rem;
            img{
                height:0.11rem;
                width:0.14rem;
                margin: 0 0.2rem;
            }
            span{
                padding: 0 0.25rem 0 0.07rem;
            }
        }
    }
}
/deep/ .el-drawer__header>span{
  outline: none;
}
.el-drawer__header>span:focus{
  outline: none!important;
}
.el-drawer__header>:first-child{
  outline: none!important;
    outline: 0!important;
}
.el-drawer__header>:first-child:focus{
  outline: none!important;
    outline: 0!important;
}
</style>
